import React, { useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import ImageBlock from './ImageBlock';
import styles from './FlexiTile.module.scss';
import { isUndefined, setTextPosition } from './ImageBlockUtilities';

const FlexiTile = ({ model, id, isDesignerMode }) => {
  const elementId = `flexi-tile-content-block-${id}`;
  const coreHeightVars = ['--minHeight_Desktop', '--minHeight_Tablet', '--minHeight_Mobile'];
  const [isAdminElement, setIsAdminElement] = useState(false);
  const { background, button, layout } = { ...model };
  const textPositionClassNamesString = setTextPosition(Number(layout.buttonTextPosition));
  let buttonDisplayStyle = 'none';
  const tileBackgroundStyles = background.useBackgroundImage ?
    { backgroundImage: 'unset' } :
    {
      backgroundColor: !isUndefined(background.backgroundColor)
        && background.backgroundColor !== null &&
        background.backgroundColor,
    };

  if (!isUndefined(button) && !isUndefined(button.buttonText) && button.buttonText.length)
    buttonDisplayStyle = 'inline-block';

  useEffect(() => {
      if (isDesignerMode) {
        setIsAdminElement(true);
      }
  }, [isAdminElement]);    

  useLayoutEffect(() => {
    const getElement = document.getElementById(elementId);
    let elementHeight = getElement?.parentNode?.style?.minHeight;
    if (!elementHeight)
    {
      const styles = getElement?.parentNode?.style;
      if(styles && Object.values(styles).every(element=> !coreHeightVars.includes(element)))
        elementHeight = '400px';
      else
        elementHeight = getComputedStyle(getElement.parentNode)?.minHeight;
    }
    document.getElementById(elementId).style.setProperty(
      '--cb-flexi-min-height',
      elementHeight,
    );
  });

  return (
    <>
      <ImageBlock 
        elementId={elementId} 
        tileData={model}
        textPositionClassNamesString={textPositionClassNamesString}
        tileBackgroundStyles={tileBackgroundStyles}
        buttonDisplayStyle={buttonDisplayStyle}
        adminClasses={isDesignerMode && isAdminElement ? styles.adminMinHeight : null}
      />
    </>
  );
};

FlexiTile.propTypes = {
    model: PropTypes.object.isRequired,
    id:PropTypes.string,
    isDesignerMode:PropTypes.bool,
};

export default React.memo(FlexiTile);